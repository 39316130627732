import React from 'react';
import { FaEye, FaLock, FaSatellite, FaChartBar } from 'react-icons/fa';

const UnmannedSurveillance = () => {
  return (
    <div className="py-20 bg-gray-900">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-12 text-center text-cyan-400">
          <FaEye className="inline-block mr-2" />
          Industrial Unmanned Surveillance
        </h1>
        
        <section className="mb-16 bg-gray-800 p-8 rounded-3xl shadow-lg">
          <h2 className="text-3xl font-semibold mb-6 text-cyan-300">Value Proposition</h2>
          <p className="text-xl mb-8 text-gray-300">
            Cyberwave's Unmanned Surveillance solutions offer unparalleled security and monitoring capabilities for industrial environments:
          </p>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              { icon: FaSatellite, title: "24/7 Monitoring", description: "Continuous surveillance of large areas with minimal human intervention." },
              { icon: FaLock, title: "Enhanced Security", description: "Early detection of security breaches and unauthorized access attempts." },
              { icon: FaChartBar, title: "Data-Driven Insights", description: "Advanced analytics to identify patterns and predict potential security risks." },
              { icon: FaEye, title: "Remote Accessibility", description: "Real-time video feeds and alerts accessible from anywhere, anytime." },
            ].map((item, index) => (
              <li key={index} className="flex items-start">
                <item.icon className="text-cyan-400 text-3xl mr-4 mt-1" />
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-cyan-200">{item.title}</h3>
                  <p className="text-gray-300">{item.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </section>

        {/* New section with images */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-gray-800 rounded-lg p-4">
            <img 
              src="/images/drone-high.webp" 
              alt="High-Altitude Drone Surveillance" 
              className="w-full h-auto rounded-lg mb-4 object-cover"
            />
            <h3 className="text-xl font-semibold mb-2 text-cyan-300">High-Altitude Surveillance</h3>
            <p className="text-gray-300">Our high-flying drones provide expansive coverage for large industrial areas, ensuring comprehensive security monitoring from above.</p>
          </div>
          <div className="bg-gray-800 rounded-lg p-4">
            <img 
              src="/images/use-cases/drone-unmanned-surveillance.webp" 
              alt="Unmanned Drone Surveillance" 
              className="w-full h-auto rounded-lg mb-4 object-cover"
            />
            <h3 className="text-xl font-semibold mb-2 text-cyan-300">Autonomous Patrol Drones</h3>
            <p className="text-gray-300">Our autonomous drones conduct regular patrols, providing real-time surveillance and rapid response capabilities for enhanced security.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnmannedSurveillance;
