import React from 'react';

const AutomatedWelding = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-6 text-cyan-400">Automated Welding</h1>
      <div className="bg-gray-800 rounded-lg p-6 mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-cyan-300">Revolutionizing Manufacturing with Precision</h2>
        <p className="text-gray-300 mb-4">
          Our advanced automated welding solutions are revolutionizing manufacturing processes. By combining robotics with AI-driven control systems, we enhance precision, speed, and consistency in industrial welding applications.
        </p>
        <ul className="list-disc list-inside text-gray-300 mb-4">
          <li>AI-optimized welding parameters for various materials and thicknesses</li>
          <li>Real-time quality control and defect detection</li>
          <li>Integration with IoT for predictive maintenance of welding equipment</li>
        </ul>
      </div>
      {/* Add more sections as needed */}
    </div>
  );
};

export default AutomatedWelding;
