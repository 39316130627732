import React from 'react';
import Hero from './Hero';
import Features from './Features';
import About from './About';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="container mx-auto mt-6">
      <h1 className="text-4xl font-bold mb-4">Welcome to Cyberwave</h1>
      <div className="w-full flex justify-center mb-6">
        <div className="w-full md:w-3/5 relative rounded-3xl overflow-hidden" style={{ paddingTop: '33.75%' }}>
          <video 
            className="absolute top-0 left-0 w-full h-full object-cover rounded-3xl"
            autoPlay 
            loop 
            muted 
            playsInline
          >
            <source src="https://storage.cloud.google.com/cyberwave-website/cyberwave-ai-assisted-v2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      
      <div className="w-full bg-gray-800 py-4 mb-6 rounded-2xl shadow-md">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl font-semibold text-cyan-400 mb-2">AI-Assisted Controls</h2>
          <p className="text-gray-300">
            Experience the future of robotics with Cyberwave's AI-assisted control systems. 
            Our cutting-edge technology enhances precision, efficiency, and safety in various industries.
          </p>
          <Link to="/technology" className="inline-block mt-4 bg-cyan-500 hover:bg-cyan-600 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300">
            Learn More About Our Technology
          </Link>
        </div>
      </div>

      <Hero />
      <Features />
      <About />
      <section className="py-8 bg-gray-800 rounded-2xl mb-8">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4 text-cyan-400">Explore More</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-gray-700 p-4 rounded-2xl shadow-lg">
              <h3 className="text-xl font-semibold mb-2 text-cyan-300">Our Research</h3>
              <p className="mb-4">Discover our pioneering work in AI and robotics</p>
              <Link to="/research" className="inline-block bg-cyan-500 text-white px-4 py-2 rounded-full hover:bg-cyan-600 transition-colors">
                Learn More
              </Link>
            </div>
            <div className="bg-gray-700 p-4 rounded-2xl shadow-lg">
              <h3 className="text-xl font-semibold mb-2 text-cyan-300">Master Plan</h3>
              <p className="mb-4">Learn about our roadmap to the future</p>
              <Link to="/masterplan" className="inline-block bg-cyan-500 text-white px-4 py-2 rounded-full hover:bg-cyan-600 transition-colors">
                View Plan
              </Link>
            </div>
            <div className="bg-gray-700 p-4 rounded-2xl shadow-lg">
              <h3 className="text-xl font-semibold mb-2 text-cyan-300">Technology</h3>
              <p className="mb-4">Explore our cutting-edge technology platform</p>
              <Link to="/technology" className="inline-block bg-cyan-500 text-white px-4 py-2 rounded-full hover:bg-cyan-600 transition-colors">
                Discover More
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
