import React from 'react';

const RenewableEnergyInspection = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-6 text-cyan-400">Renewable Energy Inspection</h1>
      <div className="bg-gray-800 rounded-lg p-6 mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-cyan-300">Optimizing Energy Production with AI</h2>
        <p className="text-gray-300 mb-4">
          Our AI-powered drones and robots provide efficient and accurate inspections for solar panels, wind turbines, and oil rigs. By leveraging advanced computer vision and machine learning algorithms, we optimize maintenance schedules and increase productivity in the energy sector.
        </p>
        <ul className="list-disc list-inside text-gray-300 mb-4">
          <li>Automated inspection of solar panels for defects and efficiency loss</li>
          <li>Wind turbine blade analysis for early detection of damage</li>
          <li>Oil rig structural integrity assessment using advanced sensors</li>
        </ul>
      </div>
      
      {/* Updated section with actual images */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div className="bg-gray-800 rounded-lg p-4">
          <img 
            src="/images/use-cases/drone-solar-panels-farm-inspection.webp" 
            alt="Solar Panel Inspection" 
            className="w-full h-auto rounded-lg mb-4 object-cover"
          />
          <h3 className="text-xl font-semibold mb-2 text-cyan-300">Solar Panel Inspection</h3>
          <p className="text-gray-300">Our AI drones efficiently scan large solar farms, identifying defects and performance issues with high accuracy.</p>
        </div>
        <div className="bg-gray-800 rounded-lg p-4">
          <img 
            src="/images/use-cases/drone-wind-turbine-inspection.webp" 
            alt="Wind Turbine Analysis" 
            className="w-full h-auto rounded-lg mb-4 object-cover"
          />
          <h3 className="text-xl font-semibold mb-2 text-cyan-300">Wind Turbine Analysis</h3>
          <p className="text-gray-300">Advanced imaging techniques allow our AI to detect minute damages on wind turbine blades, preventing costly failures.</p>
        </div>
      </div>
      {/* Add more sections as needed */}
    </div>
  );
};

export default RenewableEnergyInspection;
