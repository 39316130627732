import React from 'react';
import CyberwavePlatform from './CyberwavePlatform';

function Technology({ icon }) {
  return (
    <div className="container mx-auto mt-6">
      <h1 className="text-4xl font-bold mb-4">
        {icon} Cyberwave Technology
      </h1>
      <p className="mb-6 text-lg">
        Explore the cutting-edge technology behind Cyberwave's AI-assisted control systems and robotic platforms.
      </p>
      <CyberwavePlatform />
      
      <div className="mt-12">
        <h2 className="text-3xl font-bold mb-6 text-center text-cyan-400">Cyberwave Technology Stack</h2>
        <div className="flex justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 380" className="w-full max-w-2xl">
            <defs>
              <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{stopColor:"#4a90e2", stopOpacity:1}} />
                <stop offset="100%" style={{stopColor:"#63b3ed", stopOpacity:1}} />
              </linearGradient>
            </defs>
            
            {/* Core Platform */}
            <rect x="20" y="320" width="400" height="50" rx="25" fill="url(#grad1)" />
            <text x="220" y="352" fontFamily="Arial, sans-serif" fontSize="18" fill="white" textAnchor="middle">Core Platform</text>
            
            {/* AI Brain */}
            <rect x="45" y="245" width="350" height="50" rx="25" fill="url(#grad1)" />
            <text x="220" y="277" fontFamily="Arial, sans-serif" fontSize="18" fill="white" textAnchor="middle">AI Brain</text>
            
            {/* Drones & Robots */}
            <rect x="70" y="170" width="300" height="50" rx="25" fill="url(#grad1)" />
            <text x="220" y="202" fontFamily="Arial, sans-serif" fontSize="18" fill="white" textAnchor="middle">Drones &amp; Robots</text>
            
            {/* Software Integration */}
            <rect x="95" y="95" width="250" height="50" rx="25" fill="url(#grad1)" />
            <text x="220" y="127" fontFamily="Arial, sans-serif" fontSize="18" fill="white" textAnchor="middle">Software Integration</text>
            
            {/* Human Interface */}
            <rect x="120" y="20" width="200" height="50" rx="25" fill="url(#grad1)" />
            <text x="220" y="52" fontFamily="Arial, sans-serif" fontSize="18" fill="white" textAnchor="middle">Human Interface</text>
            
            {/* Linear Adaptive Learning Arrow */}
            <line x1="450" y1="370" x2="450" y2="10" stroke="#ffffff" strokeWidth="3" />
            <polygon points="445,10 450,0 455,10" fill="#ffffff" />
            <text x="470" y="190" fontFamily="Arial, sans-serif" fontSize="16" fill="white" textAnchor="middle" transform="rotate(-90 470 190)">Adaptive Learning</text>
          </svg>
        </div>
      </div>

      <p className="mt-8 text-center text-gray-300">
        Our technology stack is designed for seamless integration, from the core platform to the human interface, with the AI Brain at the center coordinating all components. Adaptive learning continuously improves the system's performance across all layers.
      </p>
    </div>
  );
}

export default Technology;
