import React from 'react';

const Research = ({ icon }) => {
  return (
    <div className="py-20 bg-gray-900">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-12 text-center text-cyan-400">
          {icon} Cutting-Edge Research at Cyberwave
        </h1>
        
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-cyan-300">Our Mission</h2>
          <p className="text-xl mb-4">
            At Cyberwave, our research is dedicated to pushing the boundaries of digital twin technology, reinforcement learning, and AI-driven planning to revolutionize robotic systems and human-machine interaction.
          </p>
          <p className="text-xl mb-4">
            We strive to develop advanced cybernetic systems that seamlessly integrate with human operators, enhancing capabilities while ensuring robust cybersecurity measures to protect these critical technologies.
          </p>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-cyan-300">Focus Areas</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gray-800 p-6 rounded-2xl">
              <h3 className="text-2xl font-semibold mb-4 text-cyan-200">Digital Twin Technology</h3>
              <p>We're developing sophisticated digital twin models to simulate and optimize complex robotic systems, enabling better prediction and control in real-world scenarios.</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-2xl">
              <h3 className="text-2xl font-semibold mb-4 text-cyan-200">Reinforcement Learning in Robotics</h3>
              <p>Our research focuses on applying advanced reinforcement learning algorithms to enhance the adaptability and decision-making capabilities of robotic systems in dynamic environments.</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-2xl">
              <h3 className="text-2xl font-semibold mb-4 text-cyan-200">Remote Teleoperation</h3>
              <p>We're exploring cutting-edge teleoperation technologies to enable precise control of robotic systems from a distance, expanding their applicability in hazardous or inaccessible environments.</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-2xl">
              <h3 className="text-2xl font-semibold mb-4 text-cyan-200">AI-Driven Reasoning and Planning</h3>
              <p>Our research aims to develop advanced AI algorithms for complex reasoning and planning tasks, allowing robotic systems to make intelligent decisions in uncertain situations.</p>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-3xl font-semibold mb-6 text-cyan-300">Recent Innovations</h2>
          <ul className="space-y-4">
            <li className="bg-gray-800 p-4 rounded-xl">
              <h3 className="text-xl font-semibold mb-2">Human-Device Interaction Framework</h3>
              <p>Developing intuitive interfaces and interaction models to enhance the synergy between human operators and advanced robotic systems.</p>
            </li>
            <li className="bg-gray-800 p-4 rounded-xl">
              <h3 className="text-xl font-semibold mb-2">Hierarchical Goal-Setting in Cybernetics</h3>
              <p>Creating a novel framework for hierarchical goal-setting in cybernetic systems, allowing for more efficient and adaptable task execution in complex scenarios.</p>
            </li>
            <li className="bg-gray-800 p-4 rounded-xl">
              <h3 className="text-xl font-semibold mb-2">Advanced Cybersecurity for Robotic Systems</h3>
              <p>Implementing state-of-the-art cybersecurity measures to protect our advanced robotic and AI systems from potential threats and ensure safe, reliable operation.</p>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Research;
