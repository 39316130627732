import React from 'react';
import { FaBolt, FaShieldAlt, FaBroadcastTower, FaCamera, FaRobot } from 'react-icons/fa';

const PowerLineInspection = () => {
  return (
    <div className="py-20 bg-gray-900">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-12 text-center text-cyan-400">
          <FaBolt className="inline-block mr-2" />
          Power Line Inspection
        </h1>
        
        <section className="mb-16 bg-gray-800 p-8 rounded-3xl shadow-lg">
          <h2 className="text-3xl font-semibold mb-6 text-cyan-300">
            <FaShieldAlt className="inline-block mr-2" />
            Enhancing Grid Reliability and Safety
          </h2>
          <p className="text-xl mb-8 text-gray-300">
            Our cutting-edge drone technology revolutionizes power line inspection, offering a comprehensive solution for monitoring and maintaining electrical infrastructure. From cell towers to transmission lines, our AI-powered drones provide unparalleled insights and efficiency.
          </p>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              { icon: FaBroadcastTower, title: "Infrastructure Inspection", description: "Inspection of cell towers, transmission towers, and utility poles for structural issues" },
              { icon: FaBolt, title: "Power Line Analysis", description: "Detailed power line inspection for damage and vegetation encroachment" },
              { icon: FaCamera, title: "High-Resolution Imagery", description: "High-resolution imagery capture for in-depth analysis" },
              { icon: FaRobot, title: "AI-Driven Maintenance", description: "AI-driven defect detection and predictive maintenance" },
              { icon: FaShieldAlt, title: "Enhanced Safety", description: "Increased safety by reducing the need for manual inspections in hazardous areas" },
            ].map((item, index) => (
              <li key={index} className="flex items-start">
                <item.icon className="text-cyan-400 text-3xl mr-4 mt-1" />
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-cyan-200">{item.title}</h3>
                  <p className="text-gray-300">{item.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </section>

        {/* New section with images */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-gray-800 rounded-lg p-4">
            <img 
              src="/images/use-cases/power-line.webp" 
              alt="Power Line Inspection" 
              className="w-full h-auto rounded-lg mb-4 object-cover"
            />
            <h3 className="text-xl font-semibold mb-2 text-cyan-300">Advanced Power Line Inspection</h3>
            <p className="text-gray-300">Our AI-powered drones conduct thorough inspections of power lines, identifying potential issues and ensuring the reliability of electrical infrastructure.</p>
          </div>
          <div className="bg-gray-800 rounded-lg p-4">
            <img 
              src="/images/use-cases/operator-with-drone.webp" 
              alt="Drone Operator" 
              className="w-full h-auto rounded-lg mb-4 object-cover"
            />
            <h3 className="text-xl font-semibold mb-2 text-cyan-300">Expert Drone Operations</h3>
            <p className="text-gray-300">Our skilled operators work with state-of-the-art drones to provide comprehensive inspections, combining human expertise with advanced technology.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerLineInspection;
