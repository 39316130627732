import React from 'react';

const Logo = () => {
  return (
    <div className="p-2 rounded">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 175" className="h-8 w-auto text-cyan-400">
        <path d="M 473.165 77.892 L 458.186 77.895 L 463.593 62.538 L 478.572 62.538 L 473.165 77.892 Z M 491.511 54.125 C 489.531 49.915 485.367 47.182 481.347 47.182 L 455.899 47.182 L 434.971 106.677 L 442.665 122.034 L 470.785 121.891 L 476.136 106.676 L 450.764 106.677 C 449.56 106.677 448.537 105.327 448.925 104.239 L 453.059 92.49 L 481.131 92.489 L 492.006 61.558 C 492.804 59.303 492.68 56.619 491.511 54.125" id="path72" style={{fill: "currentColor"}}></path>
        <path d="M 141.147 106.558 L 128.87 106.558 C 127.668 106.558 126.65 105.207 127.03 104.119 L 141.705 62.42 L 156.677 62.419 L 141.147 106.558 Z M 168.282 51.793 C 166.077 48.865 162.719 47.063 159.462 47.063 L 147.105 47.063 L 159.787 11.02 L 146.686 11.02 L 123.47 76.857 L 117.947 92.215 L 118.048 92.215 L 112.984 106.551 L 120.625 121.891 L 138.587 121.906 C 144.925 121.91 150.091 118.369 152.108 112.634 L 170.174 61.285 C 171.214 58.339 170.491 54.724 168.282 51.793" id="path74" style={{fill: "currentColor"}}></path>
        <path d="M 112.828 47.067 L 92.048 106.561 L 79.77 106.561 C 78.567 106.561 77.552 105.212 77.931 104.121 L 98.006 47.067 L 84.913 47.068 L 63.978 106.562 L 71.672 121.918 L 86.65 121.918 L 72.323 162.635 L 85.425 162.635 L 126.232 47.064 L 112.828 47.067" id="path76" style={{fill: "currentColor"}}></path>
        <path d="M 76.977 47.068 L 84.658 25.246 C 85.929 21.615 84.54 16.972 80.994 13.843 C 78.886 11.991 76.263 11.024 73.774 11.024 L 58.734 11.024 C 52.404 11.027 47.245 14.565 45.229 20.297 L 14.877 106.567 L 22.572 121.921 L 40.405 121.919 C 46.733 121.919 51.892 118.379 53.908 112.649 L 61.038 92.375 L 47.921 92.275 L 42.886 106.564 L 30.67 106.564 C 29.468 106.564 28.452 105.214 28.83 104.125 L 56.183 26.381 L 71.16 26.381 L 63.884 47.069 L 76.977 47.068" id="path78" style={{fill: "currentColor"}}></path>
        <path d="M 228.241 92.959 L 228.342 92.685 L 228.241 92.959 Z" id="path80" style={{fill: "currentColor"}}></path>
        <path d="M 276.145 47.057 L 276.022 47.418 L 276.145 47.057 Z" id="path82" style={{fill: "currentColor"}}></path>
        <path d="M 331.016 47.064 L 313.044 98.144 L 307.947 70.169 L 308.002 70.095 L 292.488 70.095 L 268.978 101.072 L 287.982 47.064 L 274.881 47.064 L 253.945 106.551 L 253.953 106.551 L 261.647 121.907 L 261.647 121.927 L 268.66 121.927 L 297.818 83.516 L 304.766 121.667 L 304.674 121.927 L 317.774 121.927 L 344.117 47.064 L 331.016 47.064" id="path84" style={{fill: "currentColor"}}></path>
        <path d="M 359.195 106.545 L 346.908 106.545 C 345.706 106.546 344.692 105.195 345.079 104.105 L 349.206 92.358 L 364.183 92.357 L 359.195 106.545 Z M 386.33 51.781 C 384.118 48.85 380.76 47.05 377.501 47.05 L 352.051 47.052 L 346.646 62.407 L 374.716 62.405 L 369.318 77.76 L 341.245 77.763 L 331.124 106.546 L 338.819 121.902 L 356.644 121.9 C 362.974 121.9 368.132 118.361 370.148 112.63 L 388.214 61.272 C 389.253 58.324 388.532 54.709 386.33 51.781" id="path86" style={{fill: "currentColor"}}></path>
        <path d="M 435.9 47.05 L 394.899 101.072 L 413.911 47.05 L 400.81 47.05 L 379.875 106.546 L 387.57 121.907 L 387.57 121.927 L 394.582 121.927 L 451.421 47.05 L 435.9 47.05" id="path88" style={{fill: "currentColor"}}></path>
        <path d="M 190.806 62.417 L 205.777 62.415 L 200.378 77.771 L 185.398 77.774 L 190.806 62.417 Z M 263.168 54.004 C 261.191 49.795 257.016 47.058 253.006 47.059 L 246.126 47.059 L 246.126 47.057 L 232.211 47.058 L 211.277 106.553 L 177.969 106.557 C 176.766 106.557 175.752 105.205 176.131 104.115 L 180.265 92.368 L 208.336 92.367 L 219.219 61.438 C 220.01 59.182 219.885 56.497 218.715 54.004 C 216.745 49.795 212.571 47.058 208.561 47.059 L 183.11 47.062 L 162.176 106.557 L 169.872 121.911 L 208.732 121.91 C 215.062 121.91 220.219 118.369 222.237 112.639 L 239.906 62.414 L 246.126 62.414 L 246.126 62.412 L 263.325 62.412 L 263.664 61.438 C 264.456 59.182 264.331 56.497 263.168 54.004" id="path90" style={{fill: "currentColor"}}></path>
      </svg>
      
    </div>
  );
};

export default Logo;
