import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8 mt-8">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold mb-4">Join us in shaping the future of work and industry with Cyberwave's innovative robotic solutions.</h2>
          <Link to="/contact" className="bg-cyan-500 hover:bg-cyan-600 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300">
            Contact Us
          </Link>
        </div>
        
        <div className="border-t border-gray-700 pt-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm text-gray-400 mb-4 md:mb-0">
              © 2024 Cyberwave by D-26 SRL - VAT Code: IT 16711921003. All rights reserved.
            </p>
            <div className="flex space-x-4">
              <Link to="/privacy" className="text-sm text-cyan-400 hover:text-cyan-300 hover:underline">Privacy Policy</Link>
              <Link to="/terms" className="text-sm text-cyan-400 hover:text-cyan-300 hover:underline">Terms of Service</Link>
              <Link to="/contact" className="text-sm text-cyan-400 hover:text-cyan-300 hover:underline">Contact</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
