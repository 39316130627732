import React from 'react';
import { Button } from "./ui/button";

const About = () => {
  return (
    <section id="about" className="py-10 bg-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center text-cyan-400">About Cyberwave</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="bg-gray-800 p-8 rounded-2xl shadow-lg">
            <h3 className="text-2xl font-semibold mb-4 text-cyan-300">Our Mission</h3>
            <p className="text-gray-300 mb-6">
              At Cyberwave, we're at the forefront of robotic innovation, combining cutting-edge AI with precision engineering to create the next generation of automated solutions.
            </p>
            <p className="text-gray-300 mb-6">
              Our mission is to empower businesses and industries with intelligent robotic systems that enhance productivity, accuracy, and efficiency.
            </p>
          </div>
          
          <div className="bg-gray-800 p-8 rounded-2xl shadow-lg">
            <h3 className="text-2xl font-semibold mb-4 text-cyan-300">Our Goal</h3>
            <p className="text-gray-300 mb-6">
              Our goal is to deploy autonomous robo-workers to support us on a global scale, leveraging Italian expertise in robotics.
            </p>
            <p className="text-gray-300 mb-6">
              We aim to create a future where advanced robotics and AI work seamlessly alongside humans, enhancing our capabilities and allowing us to focus on more creative and fulfilling tasks.
            </p>
          </div>
        </div>
        
        <div className="mt-12 bg-gray-800 p-8 rounded-2xl shadow-lg">
          <h3 className="text-2xl font-semibold mb-4 text-cyan-300">Why Choose Cyberwave?</h3>
          <ul className="list-disc list-inside text-gray-300 space-y-2">
            <li>Cutting-edge AI and robotics technology</li>
            <li>Italian expertise in precision engineering</li>
            <li>Commitment to enhancing global productivity</li>
            <li>Focus on creating safe and efficient autonomous systems</li>
            <li>Dedication to pushing the boundaries of what's possible in robotics</li>
          </ul>
        </div>
        
        <div className="mt-12 text-center">
          <p className="text-xl text-gray-300 mb-6">
            Join us in shaping the future of work and industry with Cyberwave's innovative robotic solutions.
          </p>
          <Button variant="default" className="bg-cyan-500 hover:bg-cyan-600">
            Learn More About Our Technology
          </Button>
        </div>
      </div>
    </section>
  );
};

export default About;
