import React from 'react';
import { Cpu, Code, Users, Brain, Database } from 'lucide-react';
import { FaPlane, FaRobot } from 'react-icons/fa';

const CyberwavePlatform = () => {
  const iconSize = 40;
  const iconColor = "#4a90e2";
  const lineColor = "rgba(74, 144, 226, 0.3)"; // More transparent blue

  return (
    <div className="p-8 bg-gray-800 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-6 text-center text-cyan-400">Cyberwave Technology Platform</h2>
      
      <div className="relative" style={{ height: '400px', width: '100%' }}>
        {/* Curved connecting lines */}
        <svg className="absolute top-0 left-0 w-full h-full" viewBox="0 0 400 400" style={{ zIndex: 0 }}>
          {/* Top three curved lines */}
          <path d="M 70 70 Q 200 140, 200 200" stroke={lineColor} strokeWidth="2" fill="none" />
          <path d="M 200 70 Q 200 135, 200 200" stroke={lineColor} strokeWidth="2" fill="none" />
          <path d="M 330 70 Q 200 140, 200 200" stroke={lineColor} strokeWidth="2" fill="none" />
          
          {/* Bottom three curved lines */}
          <path d="M 70 330 Q 200 260, 200 200" stroke={lineColor} strokeWidth="2" fill="none" />
          <path d="M 200 330 Q 200 265, 200 200" stroke={lineColor} strokeWidth="2" fill="none" />
          <path d="M 330 330 Q 200 260, 200 200" stroke={lineColor} strokeWidth="2" fill="none" />
        </svg>

        {/* Central AI Brain */}
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center" style={{ zIndex: 2 }}>
          <Brain size={iconSize * 1.5} color={iconColor} />
          <span className="mt-2 font-semibold text-sm text-white">AI Brain</span>
        </div>
        
        {/* Surrounding components */}
        <div className="absolute inset-0 grid grid-cols-3 grid-rows-3 gap-4" style={{ zIndex: 1 }}>
          {[
            { icon: <Cpu size={iconSize} color={iconColor} />, label: "Core Platform" },
            { icon: <FaPlane size={iconSize} color={iconColor} />, label: "Drone Fleet" },
            { icon: <FaRobot size={iconSize} color={iconColor} />, label: "Ground Robots" },
            null, // Empty cell
            null, // Empty cell for center
            null, // Empty cell
            { icon: <Code size={iconSize} color={iconColor} />, label: "Software Integration" },
            { icon: <Users size={iconSize} color={iconColor} />, label: "Human Interface" },
            { icon: <Database size={iconSize} color={iconColor} />, label: "Adaptive Learning" },
          ].map((item, index) => (
            <div key={index} className={`flex flex-col items-center justify-center ${index === 4 ? 'invisible' : ''}`}>
              {item && (
                <>
                  {item.icon}
                  <span className="mt-2 font-semibold text-sm text-white">{item.label}</span>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      <p className="mt-8 text-center text-gray-300">
        Cyberwave's technology platform seamlessly integrates AI, drones, robots, and advanced software to deliver unparalleled performance in complex environments.
      </p>
    </div>
  );
};

export default CyberwavePlatform;
