import React from 'react';
import { Button } from "./ui/button";

const MasterPlan = ({ icon }) => {
  return (
    <section id="masterplan" className="py-20 bg-gray-900 text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center text-cyan-400">
          {icon} Cyberwave Master Plan
        </h2>
        <p className="text-xl mb-12 text-center">Roadmap to a future powered by advanced robotics and AI</p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <div className="relative overflow-hidden rounded-3xl">
            <div className="absolute inset-0 bg-cover bg-center filter blur-sm" style={{backgroundImage: "url('/images/drone-flying.webp')"}}></div>
            <div className="relative bg-gray-900 bg-opacity-75 p-8 rounded-2xl">
              <h3 className="text-2xl font-semibold mb-4 text-cyan-300">Our Vision</h3>
              <p className="text-gray-300">At Cyberwave, we're dedicated to expanding human capabilities through advanced AI and robotics. Our goal is to develop versatile robotic systems that can tackle complex tasks, making a positive impact on humanity and creating a better life for future generations.</p>
            </div>
          </div>
          <div className="relative overflow-hidden rounded-3xl">
            <div className="absolute inset-0 bg-cover bg-center filter blur-sm" style={{backgroundImage: "url('/images/drone-high.webp')"}}></div>
            <div className="relative bg-gray-900 bg-opacity-75 p-8 rounded-2xl">
              <h3 className="text-2xl font-semibold mb-4 text-cyan-300">The Challenge</h3>
              <p className="text-gray-300">As we face unprecedented labor shortages and an aging population, there's an increasing need for automation in various industries. Our mission is to create robotic solutions that can fill these gaps, increase productivity, and allow humans to focus on more fulfilling and creative endeavors.</p>
            </div>
          </div>
        </div>
        
        <div className="relative overflow-hidden rounded-3xl mb-12">
          <div className="absolute inset-0 bg-cover bg-center filter blur-sm" style={{backgroundImage: "url('/images/builders.webp')"}}></div>
          <div className="relative bg-gray-900 bg-opacity-75 p-8 rounded-2xl">
            <h3 className="text-3xl font-bold mb-6 text-cyan-300">Development Timeline</h3>
            <ul className="list-disc list-inside mb-8 space-y-4 text-gray-300">
              <li className="text-xl"><span className="font-semibold">2024:</span> Software defined robotic automation</li>
              <li className="text-xl"><span className="font-semibold">2025:</span> Rovers</li>
              <li className="text-xl"><span className="font-semibold">2026:</span> Quadruped robots</li>
              <li className="text-xl"><span className="font-semibold">2028:</span> Humanoids</li>
            </ul>
          </div>
        </div>
        
        <div className="relative overflow-hidden rounded-3xl mb-12">
          <div className="absolute inset-0 bg-cover bg-center filter blur-sm" style={{backgroundImage: "url('/images/helmets.webp')"}}></div>
          <div className="relative bg-gray-900 bg-opacity-75 p-8 rounded-2xl">
            <h3 className="text-3xl font-bold mb-6 text-cyan-300">Key Use Cases</h3>
            <ul className="list-disc list-inside mb-8 space-y-2 text-gray-300">
              <li className="text-xl">Reasoning and decision-making in complex environments</li>
              <li className="text-xl">Enhanced security systems and surveillance</li>
              <li className="text-xl">Improved workplace and public safety</li>
              <li className="text-xl">Efficient and thorough cleaning operations</li>
              <li className="text-xl">Precision welding and manufacturing</li>
            </ul>
          </div>
        </div>
        
        <div className="text-center mt-12">
          <p className="text-2xl font-bold mb-4">Join us in shaping the future of robotics and AI</p>
          <Button variant="default" className="bg-cyan-500 hover:bg-cyan-600">
            Learn More About Our Journey
          </Button>
        </div>
      </div>
    </section>
  );
};

export default MasterPlan;
