import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from './Logo';

function Header() {
  const location = useLocation();

  return (
    <header className="bg-gray-800 text-white py-1 px-4">
      <div className="container mx-auto flex items-center justify-between">
        <NavLink to="/" className="flex items-center hover:opacity-80 transition-opacity">
          <Logo />
        </NavLink>
        <nav>
          <ul className="flex space-x-4">
            {[
              { to: "/", label: "Home" },
              { to: "/research", label: "Research" },
              { to: "/masterplan", label: "Master Plan" },
              {
                to: "/usecases",
                label: "Use Cases",
                dropdown: [
                  { to: "/usecases/health-safety", label: "Health & Safety" },
                  { to: "/usecases/unmanned-surveillance", label: "Unmanned Surveillance" },
                  { to: "/usecases/renewable-energy-inspection", label: "Renewable Energy Inspection" },
                  { to: "/usecases/bridge-infrastructure-inspection", label: "Bridge & Infrastructure Inspection" },
                  { to: "/usecases/building-facade-cleaning", label: "Building Facade Cleaning" },
                  { to: "/usecases/power-line-inspection", label: "Power Line Inspection" },
                ],
              },
              { to: "/technology", label: "Technology" },
              { to: "/contact", label: "Contact" },
            ].map(({ to, label, dropdown }) => (
              <li key={to} className="relative group">
                <NavLink 
                  to={to} 
                  className={({ isActive }) => 
                    `hover:text-blue-400 transition-colors pb-1 ${
                      isActive ? 'border-b-2 border-cyan-400 text-cyan-400' : ''
                    }`
                  }
                >
                  {label}
                </NavLink>
                {dropdown && (
                  <ul className="absolute left-0 mt-2 w-64 bg-gray-800 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-50">
                    {dropdown.map((item) => (
                      <li key={item.to}>
                        <NavLink
                          to={item.to}
                          className={({ isActive }) =>
                            `block px-4 py-2 text-sm text-white hover:bg-gray-700 hover:text-blue-400 ${
                              isActive ? 'border-l-4 border-cyan-400 bg-gray-700' : ''
                            }`
                          }
                        >
                          {item.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
