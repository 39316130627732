import React from 'react';
import { FaLightbulb } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const UseCases = () => {
  const useCases = [
    {
      title: "Health & Safety",
      description: "Our advanced AI-powered robotic systems are revolutionizing workplace safety and efficiency through automated inspection processes.",
      image: "/images/health-safety.webp",
      link: "/usecases/health-safety"
    },
    {
      title: "Unmanned Surveillance",
      description: "Enhance security and monitoring capabilities with our cutting-edge unmanned surveillance solutions, providing real-time insights and alerts.",
      image: "/images/unmanned-surveillance.webp",
      link: "/usecases/unmanned-surveillance"
    },
    {
      title: "Renewable Energy Inspection",
      description: "Our AI-powered drones and robots provide efficient and accurate inspections for solar panels, wind turbines, and oil rigs, optimizing maintenance and increasing productivity in the energy sector.",
      image: "/images/renewable-energy-inspection.webp",
      link: "/usecases/renewable-energy-inspection"
    },
    {
      title: "Bridge and Infrastructure Inspection",
      description: "Leverage our advanced robotics and AI technology for comprehensive inspections of bridges, roads, and other critical infrastructure, ensuring safety and longevity through early detection of potential issues.",
      image: "/images/bridge-infrastructure-inspection.webp",
      link: "/usecases/bridge-infrastructure-inspection"
    },
    {
      title: "Building Facade Cleaning",
      description: "Transform building maintenance with our AI-powered drones, capable of cleaning windows and exterior surfaces of tall structures safely and efficiently.",
      image: "/images/building-facade-cleaning.webp",
      link: "/usecases/building-facade-cleaning"
    },
    {
      title: "Power Line Inspection",
      description: "Enhance grid reliability and safety with our advanced drone technology, capable of inspecting power lines, cell towers, and other critical infrastructure.",
      image: "/images/power-line-inspection.webp",
      link: "/usecases/power-line-inspection"
    }
  ];

  return (
    <div className="py-12 bg-gray-900">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-center text-cyan-400">
          <FaLightbulb className="inline-block mr-2" />
          Cyberwave Use Cases
        </h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {useCases.map((useCase, index) => (
            <div key={index} className="relative overflow-hidden rounded-xl">
              <div className="absolute inset-0 bg-cover bg-center filter blur-sm" style={{backgroundImage: `url('${useCase.image}')`}}></div>
              <div className="relative bg-gray-900 bg-opacity-75 p-6 h-full flex flex-col justify-between">
                <h2 className="text-2xl font-semibold mb-3 text-cyan-300">{useCase.title}</h2>
                <div className="bg-gray-800 bg-opacity-90 p-4 rounded-xl shadow-lg flex-grow">
                  <p className="text-sm mb-3 text-gray-300">{useCase.description}</p>
                  <Link to={useCase.link} className="inline-block mt-2 bg-cyan-500 hover:bg-cyan-600 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300 text-sm">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UseCases;
