import React from 'react';
import { FaHardHat, FaChartLine, FaRobot, FaShieldAlt } from 'react-icons/fa';

const HealthSafety = () => {
  return (
    <div className="py-20 bg-gray-900">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-12 text-center text-cyan-400">
          <FaHardHat className="inline-block mr-2" />
          Health & Safety Solutions
        </h1>
        
        <section className="mb-16 bg-gray-800 p-8 rounded-3xl shadow-lg">
          <h2 className="text-3xl font-semibold mb-6 text-cyan-300">Value Proposition</h2>
          <p className="text-xl mb-8 text-gray-300">
            Cyberwave's Health & Safety solutions leverage cutting-edge AI and robotics to create safer, more efficient workplaces. Our systems provide:
          </p>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              { icon: FaRobot, title: "Automated Inspections", description: "Continuous monitoring of work environments to identify potential hazards before they become dangers." },
              { icon: FaShieldAlt, title: "Proactive Risk Management", description: "Real-time alerts and predictive analytics to prevent accidents and injuries." },
              { icon: FaChartLine, title: "Improved Compliance", description: "Automated reporting and documentation to ensure adherence to safety regulations and standards." },
              { icon: FaHardHat, title: "Enhanced Worker Protection", description: "Intelligent systems that work alongside humans to create a safer, more productive workplace." },
            ].map((item, index) => (
              <li key={index} className="flex items-start">
                <item.icon className="text-cyan-400 text-3xl mr-4 mt-1" />
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-cyan-200">{item.title}</h3>
                  <p className="text-gray-300">{item.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </section>

        {/* New section with images */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-gray-800 rounded-lg p-4">
            <img 
              src="/images/drone-flying.webp" 
              alt="Drone Inspection" 
              className="w-full h-auto rounded-lg mb-4 object-cover"
            />
            <h3 className="text-xl font-semibold mb-2 text-cyan-300">Aerial Safety Inspections</h3>
            <p className="text-gray-300">Our AI-powered drones conduct comprehensive aerial inspections, identifying potential hazards in hard-to-reach areas and ensuring workplace safety.</p>
          </div>
          <div className="bg-gray-800 rounded-lg p-4">
            <img 
              src="/images/builders.webp" 
              alt="Construction Site Safety" 
              className="w-full h-auto rounded-lg mb-4 object-cover"
            />
            <h3 className="text-xl font-semibold mb-2 text-cyan-300">Construction Site Monitoring</h3>
            <p className="text-gray-300">Advanced AI algorithms analyze real-time data from construction sites, ensuring worker safety and compliance with regulations.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthSafety;
