import React from 'react';
import { Button } from "../components/ui/button"

const Hero = () => {
  return (
    <section className="py-12 md:py-5 text-center">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-5xl font-bold mb-4">Welcome to the Future of Robotics</h2>
        <p className="text-lg md:text-xl mb-8">Cyberwave: Revolutionizing automation with cutting-edge AI and robotics</p>
        <Button variant="default">Learn More</Button>
      </div>
    </section>
  );
};

export default Hero;
