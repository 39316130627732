import React from 'react';

const BridgeInfrastructureInspection = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-6 text-cyan-400">Bridge and Infrastructure Inspection</h1>
      <div className="bg-gray-800 rounded-lg p-6 mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-cyan-300">Ensuring Structural Integrity with Robotics</h2>
        <p className="text-gray-300 mb-4">
          Our advanced robotics and AI technology provide comprehensive inspections of bridges, roads, and other critical infrastructure. By utilizing state-of-the-art sensors and data analysis, we ensure safety and longevity through early detection of potential issues.
        </p>
        <ul className="list-disc list-inside text-gray-300 mb-4">
          <li>Automated crack detection and measurement in concrete structures</li>
          <li>Corrosion assessment using multi-spectral imaging</li>
          <li>Real-time structural health monitoring with IoT sensors</li>
        </ul>
      </div>
      
      {/* New section with images */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div className="bg-gray-800 rounded-lg p-4">
          <img 
            src="/images/use-cases/drone-inspecting-bridge.webp" 
            alt="Drone Bridge Inspection" 
            className="w-full h-auto rounded-lg mb-4 object-cover"
          />
          <h3 className="text-xl font-semibold mb-2 text-cyan-300">Drone Bridge Inspection</h3>
          <p className="text-gray-300">Our AI-powered drones perform detailed inspections of bridges, identifying structural issues with precision and efficiency.</p>
        </div>
        <div className="bg-gray-800 rounded-lg p-4">
          <img 
            src="/images/use-cases/infrastructures.webp" 
            alt="Infrastructure Analysis" 
            className="w-full h-auto rounded-lg mb-4 object-cover"
          />
          <h3 className="text-xl font-semibold mb-2 text-cyan-300">Infrastructure Analysis</h3>
          <p className="text-gray-300">Advanced sensors and AI algorithms assess various types of infrastructure, ensuring safety and optimal maintenance schedules.</p>
        </div>
      </div>
      {/* Add more sections as needed */}
    </div>
  );
};

export default BridgeInfrastructureInspection;
