import React from 'react';
import { FaBuilding, FaRobot, FaShieldAlt, FaChartLine } from 'react-icons/fa';

const BuildingFacadeCleaning = () => {
  return (
    <div className="py-20 bg-gray-900">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-12 text-center text-cyan-400">
          <FaBuilding className="inline-block mr-2" />
          Building Facade Cleaning
        </h1>
        
        <section className="mb-16 bg-gray-800 p-8 rounded-3xl shadow-lg">
          <h2 className="text-3xl font-semibold mb-6 text-cyan-300">Revolutionizing Exterior Maintenance</h2>
          <p className="text-xl mb-8 text-gray-300">
            Our advanced drone technology offers a safe and efficient solution for cleaning windows and exterior surfaces of tall buildings and structures. By leveraging AI and robotics, we're transforming the way building maintenance is performed.
          </p>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              { icon: FaRobot, title: "Automated Cleaning", description: "AI-powered drones for efficient and thorough cleaning of windows and exterior surfaces." },
              { icon: FaShieldAlt, title: "Enhanced Safety", description: "Reduced risk to human workers in high-rise environments." },
              { icon: FaChartLine, title: "Increased Efficiency", description: "Faster and more cost-effective cleaning operations for tall structures." },
              { icon: FaBuilding, title: "Comprehensive Coverage", description: "Ability to reach and clean difficult-to-access areas of buildings." },
            ].map((item, index) => (
              <li key={index} className="flex items-start">
                <item.icon className="text-cyan-400 text-3xl mr-4 mt-1" />
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-cyan-200">{item.title}</h3>
                  <p className="text-gray-300">{item.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </section>

        {/* New section with images */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-gray-800 rounded-lg p-4">
            <img 
              src="/images/use-cases/drone-close-up.webp" 
              alt="Drone Close-up" 
              className="w-full h-auto rounded-lg mb-4 object-cover"
            />
            <h3 className="text-xl font-semibold mb-2 text-cyan-300">Precision Cleaning Technology</h3>
            <p className="text-gray-300">Our state-of-the-art drones are equipped with advanced cleaning mechanisms for precise and efficient facade maintenance.</p>
          </div>
          <div className="bg-gray-800 rounded-lg p-4">
            <img 
              src="/images/use-cases/drone-cleaning.webp" 
              alt="Drone Cleaning Building" 
              className="w-full h-auto rounded-lg mb-4 object-cover"
            />
            <h3 className="text-xl font-semibold mb-2 text-cyan-300">High-Rise Cleaning Operations</h3>
            <p className="text-gray-300">Witness our drones in action as they safely and effectively clean the facades of tall buildings, ensuring pristine appearances.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingFacadeCleaning;
