import React from 'react';

const Features = () => {
  const features = [
    { title: 'Advanced AI', description: 'State-of-the-art artificial intelligence for complex decision making' },
    { title: 'Precision Engineering', description: 'High-precision robotic systems for unparalleled accuracy' },
    { title: 'Scalable Solutions', description: 'Flexible and scalable robotic solutions for businesses of all sizes' },
  ];

  return (
    <section id="features" className="py-10 bg-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-12 text-center text-cyan-400">Our Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-gray-700 p-6 rounded-2xl shadow-lg transform hover:scale-105 transition-transform duration-200">
              <h3 className="text-xl font-semibold mb-4 text-cyan-300">{feature.title}</h3>
              <p className="text-gray-300">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
